//
// authentication.scss
//

// authentication pages background
body {
  padding-bottom: 0;
  &.authentication-bg {
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 0;
  }
}
.mobile-view-download {
  display: none;
}
.login-card {
  margin-bottom: 0px !important;
}

.login-page {
  .login-page-row {
    height: 100vh;
  }
}

.download-img {
  width: 180px;
  height: 55px;
}
.authentication-form {
  .form-group {
    .input-group-text {
      background-color: transparent;

      svg {
        height: 21px;
        width: 21px;
      }
    }
  }
}

.icon-fb {
  color: #3c5a98;
}

.icon-google {
  color: #e94134;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30%;

  .form-data {
    margin-top: 25px;
    width: 380px;

    .form-button {
      background-color: #272463 !important;
    }
  }
}
.auth-page-sidebar {
  padding: 0;
  background-image: url('../../../images/client-auth-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;

  .overlay {
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .background-images img {
      margin-top: 170px;
      width: 60%;
    }

    .background-images-register-page img {
      margin-top: 30%;
      width: 60%;
    }
    .font-header {
      margin-left: 200px;
      width: 500px;
      margin-top: 30px;
      h2 {
        font-size: 32px;
        color: #363d56;
      }
    }
    h5 {
      color: #5b5d63;
      margin-top: 20px;
    }
  }

  .auth-user-testimonial {
    text-align: center;
    color: $white;
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-view-download {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .overlay {
    .background-images img {
      margin-top: 200px !important;
      width: 50% !important;
    }
    .download-img {
      width: 120px !important;
      height: 35px !important;
    }
    .font-header {
      width: 280px !important;
      margin-top: 30px;
      h2 {
        font-size: 26px !important;
      }
    }
    h5 {
      margin-top: 20px;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .login-form {
    .form-data {
      margin-top: 15px;
      width: 320px;
    }
  }
  .download-img {
    width: 130px !important;
    height: 40px !important;
  }
  .login-page {
    height: 100vh;
  }
}
@media only screen and (max-width: 1600px) {
  .login-page {
    height: 100vh;

    .login-card {
      margin-bottom: 0px !important;

      .login-page-row {
        height: 0 !important;
      }
    }
  }
  .download-img {
    width: 150px !important;
    height: 45px !important;
  }
  .font-header {
    width: 380px !important;
    margin-top: 30px;
    margin-left: 180px !important;
    h2 {
      font-size: 26px !important;
    }
  }
  .login-form {
    height: 20%;
  }
}
