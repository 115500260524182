//
// footer.scss
//

@mixin footer {
  bottom: 0;
  position: absolute;
  right: 0;
  color: $gray-600;
  width: fit-content;
  .footer-links {
    a {
      color: $gray-600;
      margin-left: 1.5rem;
      transition: all 0.4s;
      &:hover {
        color: $gray-900;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.footer {
  @include footer();
  left: $leftbar-width;
  padding: 19px 15px 20px;
}

.footer_sidebar_collapsed {
  @include footer();
  left: 50px;
  padding: 0 0 2px 12px;
}

.footer-alt {
  left: 0 !important;
  text-align: center;
  background-color: transparent;
}

@include media-breakpoint-down(sm) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
