//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      font-family: 'unicons', sans-serif;
    }
  }
}
