.loading-overlay {
  position: relative;

  .spinner-div {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #636f7d29;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0);

    .text-message {
      color: white;
      font-size: 18px;
      text-shadow: 0 0 4px black;
    }
  }
}

.no-loading-overlay {
  position: page;
}

.content-spinner {
  animation: 0.9s linear infinite spinner-border !important;
  border-width: 0.12em !important;
}
